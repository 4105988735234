<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>클럽 생성</h3>
      </div>
    </template>

    <template #modalBody>
      <!--      테마 상태 공개여부,공개일 설정, 썸네일, 제목, 헤드라인, 내용-->

      <div class="info-form">
        <!--status-->
        <div class="input-radio-form club-status">
          <label class="sub-text-s2">클럽 상태</label>
          <div class="input-radio-section">
            <input-radio-groups
              :items="state.clubStatus"
              :initial-value="state.clubForm.status"
              text-size="s2"
              @update-value="(statusObj) => actions.updateClubStatus(statusObj)"
            ></input-radio-groups>
          </div>

          <div
            v-if="
              parseInt(state.clubForm.status) ===
              $const.clubStatus.reservation.value
            "
            class="start-at"
          >
            <label class="sub-text-s2 label"> 시작일 </label>
            <input-date-picker
              class="input-item"
              label="시작일"
              :date="state.clubForm.startAt"
              format="YYYY-MM-DD HH:mm"
              @updateDate="(value) => actions.updateClubStartAt(value)"
            ></input-date-picker>
          </div>
        </div>

        <!-- publishedAt       -->
        <div class="input-select-form">
          <input-switch-new
            v-model="state.clubForm.isPublic"
            label="공개 여부"
            placement="left"
            text-size="s2"
          ></input-switch-new>
        </div>
        <div v-if="state.clubForm.isPublic" class="club-published-at">
          <label class="sub-text-s2">공개일 설정</label>
          <input-date-picker
            class="input-item"
            :date="state.clubForm.publishedAt"
            format="YYYY-MM-DD HH:mm"
            @updateDate="(value) => actions.updateClubPublishedAt(value)"
          ></input-date-picker>
        </div>
        <input-basic-new
          class="input-item"
          label="제목"
          :required="true"
          :default-value="state.clubForm.title"
          @updateData="(value) => actions.updateClubTitle(value)"
        ></input-basic-new>
        <input-basic-new
          class="input-item"
          label="헤드라인"
          :default-value="state.clubForm.headline"
          @updateData="(value) => actions.updateClubHeadline(value)"
        ></input-basic-new>

        <div class="club-thumbnail">
          <label class="sub-text-s2" for="featured-image">메인 이미지</label>
          <div
            id="featured-image"
            class="featured-img"
            :class="{ empty: !state.prevFeaturedImage }"
            :style="`background-image: url(${state.prevFeaturedImage});`"
            @click="actions.openFileStack('pc')"
          >
            <div class="img-upload">
              <camera-button></camera-button>
            </div>
          </div>
        </div>

        <quill-editor
          class="input-item"
          editor-id="club-description"
          label="내용"
          placeholder=""
          :value="state.clubForm.description"
          editor-container-height="600"
          @updateValue="(value) => actions.updateClubDescription(value)"
        >
        </quill-editor>
      </div>
    </template>
    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="sub-title-s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          text="생성"
          text-size="sub-title-s1"
          :disabled="!state.isActiveSaveBtn"
          @action="actions.createEntClub()"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed, getCurrentInstance, onMounted } from "vue";
import moment from "moment-timezone";
import InputDatePicker from "../../../../components/console/inputs/InputDatePicker";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "../../../../components/console/inputs/InputBasicNew";
import FileStackService from "@/services/FileStackService";
import CameraButton from "../CameraButton/CameraButton";
import InputRadioGroups from "@/components/console/inputs/InputRadioGroups.vue";
import InputSwitchNew from "@/components/console/inputs/InputSwitchNew.vue";
import helper from "@/helper";
import { useStore } from "vuex";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import ApiService from "@/api";

export default {
  name: "EntClubCreateModal",
  components: {
    QuillEditor,
    InputSwitchNew,
    InputRadioGroups,
    ButtonBasic,
    BaseModalSide,
    InputBasicNew,
    InputDatePicker,
    CameraButton,
  },
  props: {
    clubTheme: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      prevFeaturedImage: computed(() => {
        return state.clubForm.featuredImage;
      }),
      clubForm: {
        status: 1,
        title: "",
        headline: "",
        description: "",
        descriptionTarget: "",
        featuredImage: proxy.$const.blankImage,
        introHost: "",
        introTicket: "",
        introSession: "",
        // isComing: false,
        isPublic: false,
        //todo 날짜 null 바꾸기
        publishedAt: "",
        isTemporary: false,
        startAt: "",
        themeClubResourceId: null,
      },
      errorMessage: "",
      isActiveSaveBtn: computed(() => {
        return state.clubForm.title.length > 0;
      }),
      clubStatus: [
        {
          label: proxy.$const.clubStatus.open.label,
          value: proxy.$const.clubStatus.open.value,
        },
        {
          label: proxy.$const.clubStatus.reservation.label,
          value: proxy.$const.clubStatus.reservation.value,
        },
        {
          label: proxy.$const.clubStatus.finished.label,
          value: proxy.$const.clubStatus.finished.value,
        },
        {
          label: proxy.$const.clubStatus.hidden.label,
          value: proxy.$const.clubStatus.hidden.value,
        },
      ],
    });

    onMounted(() => {
      let clubTheme = props.clubTheme;
      if (clubTheme?.resourceId) {
        state.clubForm.title = clubTheme.holderTitle
          ? `${clubTheme.holderTitle.replaceAll(
              "{{name}}",
              helper.displayUserName(state.user)
            )}`
          : clubTheme.holderTitle;
        state.clubForm.featuredImage = clubTheme.featuredImage
          ? clubTheme.featuredImage
          : proxy.$const.blankImage;
        state.clubForm.introTicket = `${helper.displayUserName(state.user)}의 ${
          clubTheme.title
        } 입장권`;
        state.clubForm.introSession = clubTheme.title
          ? `${helper.displayUserName(state.user)}의 ${
              clubTheme.title
            } LIVE 참여`
          : "";
        state.clubForm.description = clubTheme.holderDescription
          ? helper.nl2br(
              clubTheme.holderDescription.replaceAll(
                "{{name}}",
                helper.displayUserName(state.user)
              )
            )
          : "";
        state.clubForm.descriptionTarget = clubTheme.holderDescriptionTarget
          ? setUpDescriptionTarget(clubTheme.holderDescriptionTarget)
          : "";
      }
    });

    const setUpDescriptionTarget = (value) => {
      let array = value;
      let strings = array.reduce((result, current, index) => {
        if (array.length - 1 === index) {
          result += current;
        } else {
          result += current + ",";
        }
        return result;
      }, "");

      return strings;
    };

    const postAttachmentImageUrl = async (stateData, imageUrl) => {
      const formData = new FormData();
      formData.append("file", imageUrl);
      await ApiService.postAttachment(formData).then((res) => {
        if (res.data.success) {
          state.clubForm[stateData] = res.data.data.url;
        }
      });
    };

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      updateClubTitle: (value) => {
        state.clubForm.title = value;
      },
      updateClubHeadline: (value) => {
        state.clubForm.headline = value;
      },
      updateClubDescription: (value) => {
        state.clubForm.description = value;
      },
      updateClubPublishedAt: (value) => {
        state.clubForm.publishedAt = moment(value);
      },
      updateClubStatus: (statusObj) => {
        state.clubForm.status = statusObj.value;
        state.clubForm.label = statusObj.label;
      },
      updateClubStartAt: (value) => {
        state.clubForm.startAt = moment(value);
      },
      createEntClub: () => {
        emit("saveComplete", state.clubForm);
      },
      openFileStack: (type) => {
        let filestack = new FileStackService();
        filestack.options.transformations.crop.aspectRatio = 1 / 1;
        filestack.options.onFileUploadFinished = async (fileMetaData) => {
          if (type === "pc") {
            await postAttachmentImageUrl("featuredImage", fileMetaData.url);
          }
        };

        filestack.open(filestack.options);
      },
    };

    return { state, actions, moment };
  },
};
</script>

<style src="./style.css" scoped></style>
